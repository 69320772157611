/* eslint-disable */
import defaultPriceRange from "./default-price-range";
const priceRange = [
  {
    ...defaultPriceRange[0],
    price: 900,
    getcourseInputId: "3605073",
  },
  {
    ...defaultPriceRange[1],
    price: 1800,
    getcourseInputId: "3605085",
  },
  {
    ...defaultPriceRange[2],
    price: 2500,
    getcourseInputId: "3605097",
  },
  {
    ...defaultPriceRange[3],
    price: 3500,
    getcourseInputId: "3605105",
  },
];
export default {
  TechnoExplanations: [
    {
      id: "ea3c9960-f880-5d8e-af95-d98b1f86359a",
      explanationsImage: {
        src: "/images/graphic-system.jpg",
      },
      explanationsTitle: "Что такое графсистема?",
      explanationsTextTitle:
        "Комбинация элементов и принципов по определённым правилам.",
      explanationsTextDescription:
        "Графсистема строится на правилах дизайна элементов и принципах вёрстки. Усиливает насмотренность дизайнера.",
    },
    {
      id: "c7878326-2114-5e39-9a7a-15be0e3fa7ef",
      explanationsImage: {
        src: "/images/techno/explanations/02.jpg",
      },
      explanationsTitle: "Что такое графсистема Техно?",
      explanationsTextTitle: "",
      explanationsTextDescription:
        "Клубная культура из Германии. Вычищенный электронный звук. Механические гипнотические ритмы. Вокруг этой музыки собралась большая культура, в том числе визуальная. И мы изучим её. Поймём, как графдизайнят в интеллектуальном андерграунде. Туц-туц-туц-сигма.",
    },
  ],
  priceRange,
};
