// react/gatsby imports
import React from "react";
// components
import Layout from "components/layout";
import SEO from "components/layout/seo";
import CourseExplanations from "components/pages/course-page/explanations";
import CoursePriceRange from "components/pages/course-page/price-range";
import EducationQuestions from "components/pages/course-page/education-questions";
import Mailing from "components/common/mailing";
import MasterClassPortfolio from "components/pages/course-page/portfolio/MasterClassPortfolio";
import SchoolSystem from "components/pages/course-page/school-system";
import MasterClassStream from "components/pages/course-page/master-class-stream";
import MasterClassTemplateOffer from "components/pages/course-page/offer/master-class";
import PaymentChoices from "components/pages/payment-choices";
// assets
import ogImage from "assets/images/seo/techno.jpg";
import ogImageVK from "assets/images/seo/vk/techno.jpg";
// data
import localData from "data/pages/techno";
// hooks
import useTechnoPortfolio from "hooks/portfolio/useTechnoPortfolio";
import useTechnoQuery from "hooks/graphql/useTechnoQuery";
// utilities
import generateCanonicalLink from "utilities/link-utils";
// constants
import PAGE_SLUGS from "constants/page-slugs";

const TechnoPage = () => {
  const prismicData = useTechnoQuery();
  const portfolio = useTechnoPortfolio();
  const { data } = prismicData.course;
  const { data: seoData } = prismicData.page || {};
  return (
    <Layout logo="techno">
      <div className="section-top-block" />
      <SEO
        title="Графсистема Техно"
        description={
          seoData?.seodescription?.text || data.coursedescription?.text
        }
        keywords={["техно", "графсистема", "гранич", "granich"]}
        ogImage={seoData?.seoimage?.url || ogImage}
        ogImageVk={seoData?.seoimage?.thumbnails?.vk?.url || ogImageVK}
        url={generateCanonicalLink(PAGE_SLUGS.graphicSystemTechno)}
      />
      <MasterClassTemplateOffer courseName="Техно" data={data} />
      <CourseExplanations twoRows data={localData.TechnoExplanations} />
      <SchoolSystem />
      <MasterClassPortfolio
        pinterestText={"Техно в Энциклопедии\nГрафдизайна Школы"}
        pinterestLink="https://www.pinterest.ru/vadim_granich/techno/"
        portfolio={portfolio}
        graphicSystemName="Техно"
        courseTitle={data.coursetitle?.text}
      />
      <div id="participation-section" />
      {data.coursestatus ? (
        <CoursePriceRange
          formId="ltForm3153227"
          priceRange={localData.priceRange}
          courseTitle={data.coursetitle?.text}
          formPostUrl="https://school.granich.design/pl/lite/block-public/process-html?id=1479097786"
        />
      ) : (
        <Mailing />
      )}
      <PaymentChoices courseType="Мастер-класс" />
      <EducationQuestions />
      <MasterClassStream
        title="Урок по Техно"
        description="По графсистеме Техно есть урок на ютуб-канале Школы. В нём я показываю создание линеечной структуры, характерной для Техно. Полезное дополнение к этому мастер-классу."
        youtubeUrl="https://www.youtube.com/embed/8Ybw8sVkFIw"
      />
    </Layout>
  );
};

export default TechnoPage;
